<template>
  <v-app>
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <v-card>
          <v-card-title>Mock-Up EVSE</v-card-title>
          <v-card-subtitle>
            {{ "ocpp1.6" == uri.version ? "Charge Point" : "" }}
            {{ "ocpp2.0.1" == uri.version ? "Charging Station" : "" }}
          </v-card-subtitle>
          <v-card-text>
            <v-form ref="form">
              <v-row>
                <v-col cols="12" sm="4">
                  <v-row no-gutters>
                    <v-text-field
                      v-model="uri.host"
                      :rules="rules.host"
                      label="OCPP Server URI"
                      outlined
                      clearable
                      :disabled="status.connected"
                    ></v-text-field>
                  </v-row>
                  <v-row no-gutters>
                    <v-select
                      v-model="uri.version"
                      :items="status.versions"
                      label="Versions"
                      outlined
                      :disabled="status.connected"
                    ></v-select>
                  </v-row>
                  <v-row no-gutters>
                    <v-text-field
                      v-model="uri.group"
                      :rules="rules.group"
                      label="Group"
                      placeholder="Group"
                      outlined
                      clearable
                      :disabled="status.connected"
                    ></v-text-field>
                  </v-row>
                  <v-row no-gutters>
                    <v-text-field
                      v-model="uri.client"
                      :rules="rules.client"
                      label="Client"
                      placeholder="Client"
                      outlined
                      clearable
                      :disabled="status.connected"
                    ></v-text-field>
                  </v-row>
                  <v-row no-gutters>
                    <v-text-field
                      v-model="uri.token"
                      label="Bearer Token"
                      placeholder="Bearer Token"
                      outlined
                      clearable
                      :disabled="status.connected"
                    ></v-text-field>
                  </v-row>

                  <v-row no-gutters>
                    <v-btn
                      block
                      x-large
                      :color="status.color"
                      @click="statusClick"
                    >
                      {{ status.button }}
                    </v-btn>
                  </v-row>
                </v-col>

                <v-col cols="12" sm="8" :hidden="!status.connected">
                  <v-simple-table dense outlined fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>Operation</th>
                          <th>
                            <OCPPMessage
                              :socket="socket"
                              @input="onSendMessage"
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in receiveMessages" :key="item.name">
                          <td>{{ item.operation }}</td>
                          <td>{{ item.message }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>

        <v-dialog v-model="alert.dialog" max-width="400">
          <v-alert border="top" colored-border type="warning" elevation="2">
            {{ alert.message }}
          </v-alert>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Validator from "@/app/app.validator.js";
import OCPPMessage from "./OCPPMessage";

export default {
  components: {
    OCPPMessage,
  },

  data: () => ({
    alert: {
      dialog: false,
      message: null,
    },
    status: {
      connected: false,
      button: "Connect",
      color: "primary",
      versions: ["ocpp1.6", "ocpp2.0.1"],
      type: "", //group or client
    },
    rules: {
      host: [Validator.required("required")],
      group: [Validator.required("required")],
      client: [Validator.required("required")],
    },

    uri: {
      host: "",
      version: "ocpp2.0.1",
      group: "",
      client: "test",
      token: "",
    },

    socket: {
      url: null,
      headers: {
        Authorization: null,
        protocols: null,
        path: null,
      },
      session: null,
    },

    receiveMessages: [],

    receiveMessage: null,

    sendMessage: {
      payloads: [],
      payload: null,
      text: null,
    },
  }),

  methods: {
    statusClick() {
      if ("Connect" == this.status.button) {
        if (!this.$refs.form.validate()) return;

        this.uri.client = this.uri.client.replace(/ /gi, ""); // 모든 공백 제거

        this.socket.url = [
          this.uri.host,
          this.uri.version,
          this.uri.group,
          this.uri.client,
        ].join("/");
        console.log(this.socket.url);

        let url = this.socket.url;
        if (this.uri.token != null && this.uri.token != "") {
          url = url + "?access_token=" + this.uri.token;
        }

        this.socket.headers.protocols = this.uri.version;
        this.socket.session = new WebSocket(url, this.socket.headers.protocols);

        this.socket.session.onopen = this.onConnected;
        this.socket.session.onclose = this.onError;
        this.socket.session.onerror = this.onError;
        this.socket.session.onmessage = this.onReceiveMessage;
      } else {
        this.socket.session.close();
      }
    },

    onConnected(e) {
      console.log("onConnected", e);
      this.status.connected = true;
      this.status.button = "Disconnect";
      this.status.color = "error";
      this.alert.message = "";
      this.alert.dialog = false;
      this.receiveMessages = [];
    },
    onError(e) {
      console.log("onError", e);
      this.status.connected = false;
      this.status.button = "Connect";
      this.status.color = "primary";
      this.alert.message = "[" + e.code + "]" + e.type;
      this.alert.dialog = true;
    },

    onReceiveMessage(e) {
      console.log("onReceiveMessage", e);
      const msg = JSON.parse(e.data);
      console.log("onReceiveMessage", 1, msg);

      this.receiveMessages.push({ operation: "RECEIVE", message: msg });
      if (msg[0] == "2") {
        this.receiveMessage = msg[1];
      }
    },

    onSendMessage(e) {
      // console.log("onSendMessage", this.sendMessage.text);

      let msg = null;
      try {
        msg = JSON.parse(e.text);
        console.log("onSendMessage", 1, msg);
      } catch (tx) {
        this.alert.message = "" + tx;
        this.alert.dialog = true;
        console.log("onSendMessage", 2, tx);
        return;
      }

      this.socket.session.send(e.text);
      this.receiveMessages.push({ operation: "SEND", message: msg });
    },
  },

  mounted() {
    this.uri.host = this.$route.query.host;
    this.uri.group = this.$route.query.group;
    this.uri.token = this.$route.query.token;
  },
};
</script>

<style></style>
